import React, { useState, useEffect } from "react";
import { Icon, Block } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useParams } from "react-router-dom";
import { getInvoicedetail } from "../../../http/get/getApi";

const InvoicePrint = () => {
  const { event_id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        setLoading(true);
        const response = await getInvoicedetail({ event_id });
        setInvoiceData(response?.data || {});
        console.log("event_id", response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (event_id) {
      fetchInvoiceDetails();
    }
  }, [event_id]);

  // Trigger print only when loading is complete and data is available
  useEffect(() => {
    if (!loading && invoiceData) {
      setTimeout(() => window.print(), 500);
    }
  }, [loading, invoiceData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error("Fetch error:", error);
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-white">
      <Head title="Invoice Print" />
      {invoiceData && (
        <Content>
          <Block>
            <div className="invoice invoice-print">
              <div className="invoice-wrap">
                <div className="invoice-brand text-center">
                  <img src={invoiceData.logourl} alt="Company Logo" />
                </div>

                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title">Invoice To</span>
                    <div className="invoice-contact-info">
                      <h4 className="title">{invoiceData.fullname}</h4>
                      <ul className="list-plain">
                        {invoiceData.address && (
                          <li>
                            <Icon name="map-pin-fill" />
                            <span>{invoiceData.address}</span>
                          </li>
                        )}
                        {invoiceData.phone && (
                          <li>
                            <Icon name="call-fill" />
                            <span>{invoiceData.phone}</span>
                          </li>
                        )}
                        {invoiceData.email && (
                          <li>
                            <Icon name="mail" />
                            <span>{invoiceData.email}</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <h3 className="title">Invoice</h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID:</span> <span>{invoiceData.invoiceId}</span>
                      </li>
                      <li className="invoice-date">
                        <span>Date:</span> <span>{invoiceData.date}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-60">Event</th>
                          <th>Package</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceData.event_name ? (
                          <tr>
                            <td>{invoiceData.event_name}</td>
                            <td>{invoiceData.package_name}</td>
                            <td>{invoiceData.package_price}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="3">No items found</td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td>Grand Total</td>
                          <td>{invoiceData.package_price}</td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="nk-notes ff-italic fs-12px text-soft">
                      Invoice was created on a computer and is valid without the signature and seal.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </Content>
      )}
    </div>
  );
};

export default InvoicePrint;
