import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, Tooltip } from "reactstrap";
import UploadMultipleVideos from "../Profile/UploadMultipleVideo";
import Swal from "sweetalert2";
import UploadMultipleMixes from "./UploadMultipleMixes";
import audioimg from "../../../src/assets/images/audioThumbnail.png";
import ReactPlayer from "react-player";
import NavigationFooter from "../components/NavigationFooter";
import { DataContext } from "../../context/DataProvider";
import { useMutation, useQueryClient } from "react-query";
import { deleteMixes } from "../../http/delete/deleteApi";
import { getCompanyProfile } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import "../../../src/css/musicPlayer.css";
import { useNavigate } from "react-router";

const SubMixesTab = ({
  toggle,
  currentState,
  formik,
  isCompanyProfileEditing,
  videosLimit,
  videoCount,
  mixersLimit,
  mixesCount,
  subscribedpalresponce,
  planEndDate,
}) => {
  const [newMixes, setNewMixes] = useState([]);

  const { hasUnsavedChanges, setHasUnsavedChanges, setActivetab } = useContext(DataContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleAddMixes = (base64Strings) => {
    handleValidation((validation) => {
      if (validation === false) {
        setActivetab("1");
        navigate("/Company-profile/edit-profile");
      } else if (validation === true) {
        const updatedMixes = [...(formik.values.mixes || []), ...base64Strings];
        formik.setFieldValue("mixes", updatedMixes);
        formik.submitForm();
        setNewMixes([]);
      }
    });

    // const updatedMixes = [...(formik.values.mixes || []), ...base64Strings];
    // formik.setFieldValue("mixes", updatedMixes);
    // formik.submitForm();
    // setNewMixes([]);
  };

  // const handleRemoveMixes = (index) => {
  //   console.log("indexindex",index)
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "Once deleted, you will not be able to recover this Mixes!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!',
  //   //   preConfirm: () => {
  //   //     return new Promise((resolve) => {
  //   //       setTimeout(resolve, 500); // Adding a delay before resolving
  //   //     });
  //   //   }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const updatedMixes = formik.values.mixes.filter((_, i) => i !== index);
  //       formik.setFieldValue('mixes', updatedMixes);
  //       formik.submitForm();

  //       Swal.fire({
  //        title: 'Deleted!',
  //         text:'Your mixes has been deleted.',
  //         icon:'success',
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   });
  // };

  const handleValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };

  // useMutation to delete mixes
  const {
    mutate: deletemixes,
    isLoading: isMixesDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-mix-by-url"],
    mutationFn: (data) => deleteMixes(data),
    onSuccess: () => {
      queryClient.invalidateQueries("get-company-profile");
    },
  });
  const deleteMixe = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedValue = { ...value, file_type: 'audio' };          
          deletemixes(updatedValue);
          Swal.fire({
            title: "Deleted!",
            text: "Your mix has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting mixe:", error);
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <Row className="p-2 py-0">
      <Col md={4} className="border p-3 bg-white border-start-0">
        <Row>
          <Col md={12}>
            <UploadMultipleMixes
              label="Upload Mixes"
              name="files"
              handleAddMixes={handleAddMixes}
              formik={formik}
              isCompanyProfileEditing={isCompanyProfileEditing}
              mixersLimit={mixersLimit}
              mixesCount={mixesCount}
              videoCount={videoCount}
              setHasUnsavedChanges={setHasUnsavedChanges}
              subscribedpalresponce={subscribedpalresponce}
              planEndDate={planEndDate}
            />
          </Col>
        </Row>
      </Col>
      <Col md={8} className="border p-3 bg-white border-end-0">
        {isCompanyProfileEditing ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            <Row className="mt-4">
              {formik.values.mixes?.map((mixe, index) => (
                <Col key={index} md={4} lg={4} className="position-relative mb-3">
                  <ShimmerThumbnail height={80} width="100%" rounded />
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            <Row className="mt-4">
              {formik.values?.mixes?.map((mixe, index) => (
                <Col key={index} md={4} lg={4} className="position-relative mb-3">
                  <audio src={mixe.url} controls className="audio-player" alt={`uploaded ${index}`} />
                  <p
                   id={`mixeNameTooltip-${index}`}
                    className="text-break"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical", 
                      WebkitLineClamp: 2, 
                      overflow: "hidden", 
                      textOverflow: "ellipsis", 
                      whiteSpace: "normal", 
                    }}
                  >
                    {mixe?.name}
                  </p>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[index] || false} // Open state for the specific tooltip
                    target={`mixeNameTooltip-${index}`} // Unique target ID
                    toggle={() => toggleTooltip(index)}
                  >
                    {mixe?.name}
                  </Tooltip>
                  {/* <ReactPlayer style={{height: "100px", width: "100%", objectFit: "cover", border: '1px solid black'}} controls url={mixe} className="react-player border-2" width="100%" height="100%" /> */}
                  <Button
                    color="danger"
                    disabled={isCompanyProfileEditing}
                    size="xs" // You can omit this if it's not having an effect
                    className="position-absolute"
                    style={{
                      top: "0px",
                      right: "15px",
                      padding: "2px 5px", // Adjust padding to make the button smaller
                      fontSize: "0.75rem", // Smaller font size
                      lineHeight: "1", // Adjust line height
                      height: "auto", // Adjust height
                      width: "auto", // Adjust width
                    }}
                    onClick={() => deleteMixe(mixe)}
                  >
                    X
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Col>
      {/* <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="8"
        validationFunction={handleValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
        isMixesDeleting={isMixesDeleting}
        form={"updateprofile"}
      /> */}
    </Row>
  );
};

export default SubMixesTab;
