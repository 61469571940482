import React, { useEffect, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { useThemeUpdate } from "../../../provider/Theme";
import { getNotifications } from "../../../../http/get/getApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { handleSeenNotification } from "../../../../http/edit/editApi";
import { toast } from "react-toastify";

const NotificationItem = ({ text, time, id, module, route }) => {
  const date = new Date(time);

  const notificationDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const notificationTime = `${formattedHours}:${minutes} ${ampm}`;

  const navigate = useNavigate();
  const handleRedirect = () => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <div
      className="nk-notification-item"
      key={id}
      id={id}
      onClick={route === "/inquiry-list" ? handleRedirect : undefined}
      style={{ cursor: route ? "pointer" : "default" }}
    >
      <div className="nk-notification-icon">
        <Icon
          name={
            module === "1" ? "user-add" : module === "2" ? "sign-usd " : module === "3" ? "calendar-booking" : "bell"
          }
        />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="d-flex justify-content-between">
          <div className="nk-notification-time">{notificationDate}</div>
          <div className="nk-notification-time">{notificationTime}</div>
        </div>
      </div>
    </div>
  );
};

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [seen, setSeen] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);
  const [limit, setLimit] = useState(3);
  const [lastNotificationCheck, setLastNotificationCheck] = useState(
    localStorage.getItem("lastNotificationCheck") || Date.now()
  );
  const queryClient = useQueryClient();

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setLimit(3);
      }
      return newState;
    });
  };

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: notificationsError,
  } = useQuery({
    queryKey: ["get-company-notifications", limit],
    queryFn: () => getNotifications({ limit: limit, offset: 0 }),
    onSuccess: (data) => {
      setUnreadCount(data.seen_count);
    },
    staleTime: 5000,
    cacheTime: 300000,
    retry: 1,
  });

  const {
    mutate: seenNotification,
    isLoading: isPackageEditing,
    error: packageEditError,
  } = useMutation({
    mutationKey: ["handle-seen-notification"],
    mutationFn: (value) => handleSeenNotification(value),
  });

  useEffect(() => {
    localStorage.setItem("lastNotificationCheck", lastNotificationCheck);
  }, [lastNotificationCheck]);

  useEffect(() => {
    if (notificationsData?.data?.length > 0) {
      const hasNewNotifications = notificationsData.data.some(
        (notification) => new Date(notification.created_at).getTime() > lastNotificationCheck
      );
      setHasUnread(hasNewNotifications);
    } else {
      setHasUnread(false);
    }
  }, [notificationsData, lastNotificationCheck]);

  const handleMarked = (e) => {
    e.preventDefault();
    setHasUnread(false);
    toast.success("Notification Status Changed", {
      position: "top-right",
      autoClose: 3000, // Set the duration in milliseconds (3000 ms = 3 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    queryClient.invalidateQueries("get-company-notifications");
    const module = "notifications"; // Define your module or get dynamically
    const notificationId = 0; // Use your actual notification ID

    // Set the seen data
    const newSeen = {
      notification_id: 0,
      module: "",
      status: 1, // Mark as seen
    };
    setSeen(newSeen);

    seenNotification(newSeen);
    setLastNotificationCheck(Date.now());
  };

  const handleViewAll = (e) => {
    e.preventDefault();
    setLimit(10);
    queryClient.invalidateQueries("get-company-notifications");
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" style={{ margin: 0 }} toggle={toggle}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        {/* <div className="position-relative">
          <Icon name="bell" />
          {!hasUnread && (
            <span
              className="position-absolute bg-danger border border-light rounded-circle"
              style={{
                top: "-0.25rem",
                right: "-0.25rem",
                width: "0.75rem",
                height: "0.75rem",
              }}
            >12</span>
          )}
        </div> */}
        <div className="position-relative">
          <Icon name="bell" />
          {unreadCount > 0 && (
            <span
              className="position-absolute bg-danger border border-light rounded-circle d-flex align-items-center justify-content-center"
              style={{
                top: "-0.55rem",
                right: "-0.55rem",
                width: "1.5rem", // Adjust width and height for badge size
                height: "1.5rem",
                fontSize: "0.65rem", // Adjust font size for badge content
                color: "white",
                padding: "10px",
              }}
            >
              {unreadCount}
            </span>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <a href="#markasread" onClick={handleMarked}>
            Mark All as Read
          </a>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {notificationsLoading ? (
              <div className="d-flex justify-content-center">Loading...</div>
            ) : notificationsData && notificationsData?.data?.length > 0 ? (
              notificationsData?.data?.map((item) => (
                <NotificationItem
                  key={item?.id}
                  id={item?.id}
                  text={item?.description}
                  time={item?.created_at}
                  module={item?.module}
                  route={item?.link}
                  className="notification-item"
                />
              ))
            ) : (
              <div className="d-flex justify-content-center">No Notification Found</div>
            )}
          </div>
          {notificationsData?.data?.length >= limit && (
            <div className="dropdown-foot">
              <a href="#viewall" onClick={handleViewAll}>
                View All
              </a>
            </div>
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
