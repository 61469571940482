import { Button, Spinner } from "reactstrap";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../context/DataProvider";
import Swal from "sweetalert2";
import subscribeimg from "../../../src/assets/images/subscribe.jpg";

const NavigationFooter = ({
  toggle,
  currentState,
  lastPage,
  formik,
  validationFunction,
  isEventAdding,
  isEventEditing,
  isClientAdding,
  isEmployeeAdding,
  isVenueadding,
  isVenderAdding,
  isPackageAdding,
  isClientEditing,
  isEmployeeEditing,
  isVenueEditing,
  isVenderEditing,
  isPackageEditing,
  form,
  isCompanyProfileEditing,
  isMixesDeleting,
  isimageDeleting,
  navigateRoute,
  // emailError,
  emailAvialable,
  usernameAvailable,
  route,
}) => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const { hasUnsavedChanges, setHasUnsavedChanges, planEnddates, subscribedpalresponce, activeTab, setActivetab } =
    useContext(DataContext);

  const next = parseInt(currentState) + 1;
  const back = parseInt(currentState) - 1;

  const [localLoading, setLocalLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const isLoading =
    isEventAdding ||
    isEventEditing ||
    isClientAdding ||
    isEmployeeAdding ||
    isVenueadding ||
    isVenderAdding ||
    isPackageAdding ||
    isEventEditing ||
    isClientEditing ||
    isEmployeeEditing ||
    isVenueEditing ||
    isVenderEditing ||
    isPackageEditing ||
    isCompanyProfileEditing ||
    isMixesDeleting ||
    isimageDeleting;

  useEffect(() => {
    if (!isLoading && formSubmitted) {
      // if (currentState !== lastPage && id) {
      if (currentState !== lastPage) {
        setHasUnsavedChanges(false);
        // toggle(next.toString());
        validationFunction((validation) => {
          if (validation) {
            setTimeout(() => {
              toggle(next.toString());
            }, 3000);
          }
        });
      } else if (currentState === lastPage) {
        // toast.success("Company profile updated successfully!");
        setTimeout(() => {
          navigate(navigateRoute);
        }, 3000);
      }
      setFormSubmitted(false);
      setLocalLoading(false);
    }
  }, [isLoading, formSubmitted, currentState, lastPage, id, next, toggle]);

  // const handleSaveAndNext = () => {
  //   if(planEnddates> new CurrentDate)
  //   validationFunction((validation) => {
  //     if (validation === true) {
  //       setLocalLoading(true);
  //       setFormSubmitted(true);
  //       formik.submitForm();
  //     }
  //     if(currentState == lastPage){
  //       // toast.success("Company profile updated successfully!");
  //       navigate(-1);
  //     }
  //   });
  // };

  const handleSaveAndNext = () => {
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    if (!subscribedpalresponce) {

      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add images to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {

      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {

      validationFunction((validation) => {
        if (validation === false) {
          setActivetab("1");
          navigate("/Company-profile/edit-profile");
        } else if (validation === true) {
          setLocalLoading(true);
          setFormSubmitted(true);
          formik.submitForm();
        }
        if (currentState === lastPage) {
          // toast.success("Company profile updated successfully!");
          setTimeout(() => {
            navigate(navigateRoute);
          }, 3000);
        }
      });
    }
  };

  const handleNext = () => {
    validationFunction((validation) => {
      if (validation === true) {
        toggle(next.toString());
      }
    });
  };

  const nextHover = {
    hover: {
      x: 3,
    },
  };

  const prevHover = {
    hover: {
      x: -3,
    },
  };

  return (
    <div
      className={`d-flex g-2 ${
        currentState === "1" ? "justify-content-end" : "justify-content-between"
      } align-items-center mt-3`}
    >
      {currentState !== "1" && (
        // <motion.div whileHover={"hover"}>
        <Button
          color="primary"
          // className="btn-dim d-flex justify-content-center align-items-center"
          // outline
          type="button"
          onClick={() => toggle(back.toString())}
        >
          {/* <motion.span variants={prevHover}> */}
          {/* <em className="icon ni ni-arrow-left"></em> */}
          {/* </motion.span> */}
          <span className="ms-1">Previous</span>
        </Button>
        //  </motion.div>
      )}
      {form == "addEvent" || form == "updateprofile" ? (
        <motion.div whileHover={"hover"}>
          <Button
            color="primary"
            className="d-flex justify-content-center align-items-center"
            type="button"
            onClick={handleNext}
          >
            <span>Next</span>
            <motion.span variants={nextHover} className="ms-1">
              {/* <em className="icon ni ni-arrow-right"></em> */}
            </motion.span>
          </Button>
        </motion.div>
      ) : (
        <Button
          color="primary"
          // style={{
          //   background: "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
          //   border: "none",
          // }}
          // onMouseOver={(e) =>
          //   (e.currentTarget.style.background =
          //     "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
          // }
          // onMouseOut={(e) =>
          //   (e.currentTarget.style.background =
          //     "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)")
          // }
          type="button"
          onClick={handleSaveAndNext}
          disabled={isLoading || localLoading || !(emailAvialable && usernameAvailable)}
        >
          {isLoading || localLoading ? (
            <>
              <Spinner size="sm" />
              <span> Loading... </span>
            </>
          ) : (
            <>
              <span>{currentState === lastPage ? "Save" : "Save and Next"}</span>
              {/* <em className={`icon ni ni-${currentState === lastPage ? "save" : "arrow-right"}`}></em> */}
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export default NavigationFooter;
