import React, { useContext } from "react";
import { getEmployeeList } from "../../http/get/getApi";
import { Col, Row } from "reactstrap";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import NavigationHeader from "../components/NavigationHeader";
import profileimg from "../../../src/assets/images/profileimg.png";
import { DataContext } from "../../context/DataProvider";

function PreviewEmployeeDetails() {
  const { currency, setCurrency } = useContext(DataContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");

  const {
    data: employeeList,
    isLoading: employeeIsLoading,
    isError: employeeListIsError,
  } = useQuery({
    queryKey: ["get-employee-by-id", id],
    queryFn: () => getEmployeeList({ id: id }),
  });

  const employee = employeeList?.data?.employees?.[0];

  const handleImageError = (e) => {
    e.target.src = profileimg; // Set fallback image if userProfile image fails to load
  };

  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Employee Detail" : ""}
        buttonInnerText={"Back"}
        route={"/employee-list"}
        back
      />
      {employeeIsLoading ? (
        <Row className="py-0 m-4">
          {/* Left column with image */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col md={4} className="text-start mt-2">
                {/* Image */}
                <ShimmerThumbnail height={150} width={150} rounded={false} />
              </Col>
              <Col md={8} className="g-4">
                <Row className="mt-2">
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Right column with details */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <ShimmerText height={20} width={150} />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col xs={12} md={2} className="border p-3 bg-white">
              <Row>
                <Col xs={12} md={12} className="text-start">
                  {/* Image */}
                  <img
                    src={employee?.profile_image_path} // Replace with the actual image field
                    alt="employee Image"
                    onError={handleImageError}
                    style={{ width: "150px", height: "150px", borderRadius: "0%", objectFit: "cover" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} className=" bg-white p-3 g-3">
              <Row className="mt-3">
                <Col xs={6} md={5}>
                  <strong>First Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.first_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Last Name :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.last_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employee ID Number :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Start/Hired Date :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.start_hired_date || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employment Type :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_type || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employee Role/Category :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_category || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>No Of Events Done :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.no_event_done || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Website :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.website || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Home Number :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.home_phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Work Number :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.work_phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Mobile Number :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Began Profession in :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.began_profession || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employement level :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_designation || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employee Experience :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_experience || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Employee Pay Rate :</strong>
                </Col>
                <Col xs={6} md={7}>
                  {employee?.emp_pay_rate ? `${currency}` : ""}{employee?.emp_pay_rate || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={5}>
                  <strong>Hourly Rate :</strong>
                </Col>
                <Col xs={6} md={7}>
                {employee?.hourly_rate ? `${currency}` : ""}{employee?.hourly_rate || "N/A"}
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col xs={12} md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={4}>
                  <strong>Email :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {employee?.email || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Username :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.username || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Address Line 1 :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.address_1 || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Address Line 2 :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.address_2 || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Zip-code :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.zip_code || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Country :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.country_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>State :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {employee?.state || "N/A"} */}
                  {employee?.stateName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>City :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {employee?.city || "N/A"} */}
                  {employee?.cityName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Appointment Link :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.appointment_id || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Skype :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {employee?.skype_id || "N/A"}
                </Col>
              </Row>
              
              <Row>
                <Col xs={6} md={4}>
                  <strong>Biography :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {employee?.biography || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Notes :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {employee?.notes || "N/A"}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewEmployeeDetails;
