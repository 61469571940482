import React, { useState, useEffect, useRef } from "react";
import {
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  BlockDes,
  BlockHeadContent,
  Block,
  BlockBetween,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useParams, Link } from "react-router-dom";
import { getInvoicedetail } from "../../../http/get/getApi";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const InvoiceDetails = () => {
  const { event_id, invoiceId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        setLoading(true);
        const response = await getInvoicedetail({ event_id });
        setUser(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (event_id) {
      fetchInvoiceDetails();
    }
  }, [event_id, invoiceId]);

  const downloadPDF = async () => {
    const invoiceElement = document.querySelector(".invoice-wrap");

    if (invoiceElement) {
      try {
        const canvas = await html2canvas(invoiceElement, {
          scale: 2,
          useCORS: true,
        });

        const pdf = new jsPDF();
        const dataURL = canvas.toDataURL("image/png");
        const pdfWidth = 210;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(dataURL, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("invoice.pdf");
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    } else {
      console.error("Invoice element not found");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error("Fetch error:", error);
    return <div>Error: {error}</div>;
  }

  const invoiceData = user?.data || {};
  return (
    <React.Fragment>
      <Head title="Invoice Detail"></Head>
      {invoiceData && (
        <Content>
          <BlockHead>
            <BlockBetween className="mt-4 ms-3">
              <BlockHeadContent>
                <BlockTitle>
                  Invoice <strong className="text-primary small">#{invoiceData.invoiceId}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Created At: <span className="text-base">{invoiceData.date}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}event-list`}>
                {/* <Button color="light" outline className="bg-white d-none d-sm-inline-flex"> */}
                  <Button color="primary d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                </Link>
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}/invoice-list`}>
                  {/* <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none"> */}
                  <Button color="primary btn-icon d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <div className="invoice">
              <div className="invoice-action gap-2">
                <Link to="">
                  <Button
                    size="lg"
                    color="primary"
                    outline
                    className="btn-icon btn-white btn-dim  me-2"
                    onClick={downloadPDF}
                  >
                    <Icon name="download"></Icon>
                  </Button>
                </Link>
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}event-list/invoice-print/${event_id}`} target="_blank">
                  <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                    <Icon name="printer-fill"></Icon>
                  </Button>
                </Link>
              </div>
              <div className="invoice-wrap">
                <div className="invoice-brand text-center">
                  <img src={invoiceData.logourl} alt="Logo" />
                </div>

                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title" style={{ fontSize: "15px" }}>
                      Invoice To
                    </span>
                    <div className="invoice-contact-info">
                      <h4 className="title">{invoiceData.fullname}</h4>
                      <ul className="list-plain">
                        {invoiceData.address && (
                          <li>
                            <Icon name="map-pin-fill"></Icon>
                            <span>{invoiceData.address}</span>
                          </li>
                        )}
                        {invoiceData.phone && (
                          <li>
                            <Icon name="call-fill"></Icon>
                            <span>{invoiceData.phone}</span>
                          </li>
                        )}
                        {invoiceData.email && (
                          <li>
                            <Icon name="mail"></Icon>
                            <span>{invoiceData.email}</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <h3 className="title">Invoice</h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID</span>:<span>{invoiceData.invoiceId}</span>
                      </li>
                      <li className="invoice-date">
                        <span>Date</span>:<span>{invoiceData.date}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-60">Event</th>
                          <th>Package</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceData.event_name ? (
                          <tr>
                            <td>{invoiceData.event_name}</td>
                            <td>{invoiceData.package_name}</td>
                            <td>{invoiceData.package_price}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="3">No items found</td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="1"></td>
                          <td colSpan="1">Grand Total</td>
                          <td colSpan="2">{invoiceData.package_price}</td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="nk-notes ff-italic fs-12px text-soft">
                      Invoice was created on a computer and is valid without the signature and seal.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default InvoiceDetails;
