import React, { useState } from 'react';
import { Col, Row, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Icon } from '../../../components/Component';
import SubVideoTab from '../../Profile/SubVideoTab';
import UploadYoutubLinks from '../../Profile/UploadYoutubLinks';
import NavigationFooter from '../../components/NavigationFooter';
import PgraphyAlbumTab from './PgraphyAlbumTab'

const PhotoGraphyAlbum = ({    
  toggle,
  currentState,
  formik,
  isCompanyProfileEditing,
  videosLimit,
  videoCount,
  mixersLimit,
  mixesCount,
  subscribedpalresponce,
  planEndDate, }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  
  return (
    <div className="p-3">
      <Nav tabs>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("1");
            }}
          >
            <Icon name="wallet"/>
             <span>Upload Albums</span>
          </NavLink>
        </NavItem>
       
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PgraphyAlbumTab  currentState={activeTab} isCompanyProfileEditing={isCompanyProfileEditing} mixersLimit={mixersLimit} mixesCount={mixesCount} formik={formik} subscribedpalresponce={subscribedpalresponce}/>
        </TabPane>
      </TabContent>
      <div className="mt-4">
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage="8"
          validationFunction={(callbackFun) => callbackFun(true)}
          formik={formik}
          isCompanyProfileEditing={isCompanyProfileEditing}
          form={"updateprofile"}
        />
      </div>
    </div>
  );
};

export default PhotoGraphyAlbum;

