import React, { useContext } from "react";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Row } from "reactstrap";
import NavigationHeader from "../components/NavigationHeader";
import { useQuery } from "react-query";
import { getPackageList } from "../../http/get/getApi";
import { useLocation } from "react-router";
import profileimg from "../../../src/assets/images/profileimg.png";
import { DataContext } from "../../context/DataProvider";

function PreviewPackageDetails() {
  const { currency, setCurrency } = useContext(DataContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");
  console.log("id", id);
  console.log("from", from);

  const {
    data: packageData,
    isLoading: packageLoading,
    isError: packageIsError,
  } = useQuery({
    queryKey: ["get-package-by-id", id],
    queryFn: () => getPackageList({ id: id }),
    staleTime: Infinity,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
  });

  const Package = packageData?.data?.packages?.[0];

  const handleImageError = (e) => {
    e.target.src = profileimg; // Set fallback image if userProfile image fails to load
  };

  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Package Details" : ""}
        buttonInnerText={"Back"}
        route={"/package-list"}
        back
      />
      {packageLoading ? (
        <Row className="py-0 m-4">
          {/* Left column with image */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col md={4} className="text-start mt-2">
                {/* Image */}
                <ShimmerThumbnail height={150} width={150} rounded={false} />
              </Col>
              <Col md={8} className="g-4">
                <Row className="mt-2">
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Right column with details */}
          <Col md={6} className="border p-3 bg-white g-3">
            <ShimmerText height={20} width={150} />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col xs={12} md={4} className="text-start">
                  {/* Image */}
                  <img
                    src={Package?.package_file} // Replace with the actual image field
                    alt="Package Image"
                    onError={handleImageError}
                    style={{ width: "150px", height: "150px", borderRadius: "0%", objectFit: "cover" }}
                  />
                </Col>
                <Col md={8} className="g-3">
                  <Row className="mt-1">
                    <Col xs={6} md={6}>
                      <strong>Service / Package Name :</strong>
                    </Col>
                    <Col xs={6} md={6}>{Package?.package_name || "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <strong>Service / Package Price :</strong>
                    </Col>
                    <Col xs={6} md={6}>{Package?.package_price ? `${currency}${Package?.package_price}` : "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <strong>Enter Retainer Price :</strong>
                    </Col>
                    <Col xs={6} md={6}>{Package?.package_deposite_price ? `${currency}${Package?.package_deposite_price}` : "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <strong>Enter Overtime Price :</strong>
                    </Col>
                    <Col xs={6} md={6}>{Package?.package_overtime ? `${currency}${Package?.package_overtime}` : "N/A"}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={4}>
                  <strong>Package Description :</strong>
                </Col>
                <Col xs={6} md={8}>{Package?.description || "N/A"}</Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewPackageDetails;
