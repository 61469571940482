import React, { useContext, useEffect, useRef, useState } from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Col, Input, Label, Popover, PopoverBody, Row } from "reactstrap";
import Required from "../components/Required";
import { DataContext } from "../../context/DataProvider";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import subscribeimg from "../../../src/assets/images/subscribe.jpg";
import { Icon } from "../../components/Component";

const About = ({
  currentState,
  formik,
  toggle,
  isCompanyProfileEditing,
  business_descriptionlimit,
  descriptioncount,
  subscribedpalresponce,
}) => {
  const handelValidation = (callbackFun) => {
    // callbackFun(true);

    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {
      callbackFun(true);
    } else {
      callbackFun(false);
    }
  };

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  function calculateWordCount(text) {
    const trimmedText = text?.trim();
    if (!trimmedText) {
      return 0;
    }
    const words = trimmedText?.split(/\s+/);
    return words.length;
  }
  function calculateWordCount1(text) {
    const trimmedText = text?.trim();
    if (!trimmedText) {
      return 0;
    }
    const words = trimmedText?.split(/\s+/);
    return words.length;
  }

  const [description, setDescription] = useState(formik?.values?.description);
  const [unique, setUnique] = useState(formik?.values?.uniqueness);
  const [wordCount, setWordCount] = useState(calculateWordCount(description));
  const [uniqWordCount, setUniqWordCount] = useState(calculateWordCount1(unique));

  useEffect(() => {
    setDescription(formik.values.description);
    setUnique(formik.values.uniqueness);
    setWordCount(calculateWordCount(formik?.values?.description));
    setUniqWordCount(calculateWordCount1(formik?.values?.uniqueness));
  }, [formik.values?.description, formik?.values?.uniqueness]);
  const popoverRef = useRef(null);

  const [descriPtioLimit, setDescriPtiolimit] = useState("");
  const navigate = useNavigate();

  const { hasUnsavedChanges, setHasUnsavedChanges, activeTab, setActivetab } = useContext(DataContext);
  const [desWordCount, setDesWordCount] = useState(wordCount);
  const [uniqu1, setUniqu1] = useState(uniqWordCount);
  const desWordCount1 = wordCount;
  const unique2 = uniqWordCount;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    if (popoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen]);

  const handleDescriptionChange = (e) => {
    if (subscribedpalresponce) {
      const { value } = e.target;
      let wordCount = 0;

      if (value.trim() !== "") {
        wordCount = value.trim().split(/\s+/).length;
      }

      formik.setFieldValue("desCount", wordCount);
      const totalDescCount = wordCount;
      setDesWordCount(totalDescCount);

      if (
        business_descriptionlimit != 0 &&
        descriptioncount <= business_descriptionlimit &&
        totalDescCount <= business_descriptionlimit
      ) {
        setHasUnsavedChanges(true);
        formik.handleChange(e);
      } else {
        Swal.fire({
          title: "Description Limit Exceeded!!",
          text: `You've reached the limit of ${business_descriptionlimit} words for your description. Upgrade your plan to add more details and make your business stand out!`,
          icon: "warning",
          confirmButtonText: "Upgrade Now",
          cancelButtonText: "Maybe Later",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("2");
          }
        });
      }
    }
  };

  const handleSubscriptionAlert = () => {
    Swal.fire({
      title: "Subscription Required",
      text: "Subscribe to one of our plans and enjoy the ability to add description to your account.",
      imageUrl: subscribeimg,
      imageWidth: 250,
      imageHeight: 200,
      imageAlt: "Custom image",
      showCancelButton: true,
      confirmButtonText: "Subscribe Now",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/plan-selection");
      }
    });
  };

  // const handleAbout = (e) => {
  //   const { value } = e.target;
  //   console.log("value",value);
  //   let wordCount = 0;

  //   // Check if the input is not empty
  //   if (value.trim() !== "") {
  //     wordCount = value.trim().split(/\s+/).length;
  //   }

  //   // console.log("wordCountUU", wordCount);
  //   formik.setFieldValue("uniquenessCount", wordCount);
  //   setHasUnsavedChanges(true);
  //   formik.handleChange(e);
  // };

  const handleAbout = (e) => {
    const { value } = e.target;

    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }
    // Set word count in formik and in local state
    formik.setFieldValue("uniquenessCount", wordCount);

    const totalDescCount = wordCount;
    setUniqu1(totalDescCount);

    setUniqWordCount(wordCount); // Update the word count state

    if (
      uniqu1 <= 500
    ) {
      setHasUnsavedChanges(true);
      formik.handleChange(e);
    }
  };

  const handleExperience = (e) => {
    const { value } = e.target;
    let wordCount = 0;

    // Check if the input is not empty
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue("experienceCount", wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const maxLength = 500;
  const currentLength = formik?.values?.uniqueness?.length ? formik?.values?.uniqueness?.length : 0;

  return (
    <Row className="p-2 py-0">
      {/* Left Section */}
      <Col md={6} className="border p-3 bg-white">
        <section>
          <Row className="mt-3">
            <Col md={12}>
              <Label for="uniqueness">Uniqueness</Label>
              <div
                className="nk-quick-nav-icon"
                style={{ alignItems: "center", cursor: "pointer", marginLeft: "4px" }}
                id="UniqunessPopover"
              >
                <Icon name="info" className="size-md" style={{ fontSize: "1.2rem", position: "relative" }} />
              </div>
              <textarea
                className={`form-control form-control-sm ${
                  formik.errors.uniqueness && formik.touched.uniqueness ? "is-invalid" : ""
                }`}
                placeholder="Write about your uniqueness"
                // maxLength={maxLength}
                id="uniqueness"
                name="uniqueness"
                value={formik.values?.uniqueness}
                onChange={handleAbout}
                invalid={formik.errors.uniqueness && formik.touched.uniqueness}
              />
              {/* <div className="mt-1 justify-end" style={{ fontSize: "0.875rem", color: "#6c757d" }}>
                {currentLength}/{maxLength} characters
              </div> */}

              {/* Display word count here */}
              <div className="mt-1 justify-end" style={{ fontSize: "0.875rem", color: "#6c757d" }}>
                {uniqu1 ? uniqu1 : unique2}/{maxLength}
              </div>

              {formik.errors.uniqueness && formik.touched.uniqueness && (
                <p style={{ color: "red" }}>{formik.errors.uniqueness}</p>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <Label for="experience">Experience</Label>
              <Input
                type="number"
                id="experience"
                name="experience"
                className="noscroll"
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={formik.values?.experience}
                placeholder="Years of experience"
                onChange={handleExperience}
                // onChange={(e) => {
                //   const value = e.target.value;

                //   if (value.length <= 10) {
                //     formik.handleChange(e);
                //     setHasUnsavedChanges(true);
                //   }
                // }}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
        </section>
      </Col>

      {/* Right Section */}
      <Col md={6} className="border p-3 bg-white border-start-0">
        <section>
          <Row className="mt-4">
            <Col md={12}>
              <Label for="description">
                Description
                {/* <Required /> */}
              </Label>
              <textarea
                className={`form-control form-control-sm ${
                  formik.errors.description && formik.touched.description ? "is-invalid" : ""
                }`}
                placeholder="Write about your USP"
                // maxLength={5} // Adjust maxLength to handle more characters
                id="description"
                name="description"
                value={formik.values?.description}
                onChange={handleDescriptionChange}
                onKeyDown={!subscribedpalresponce ? handleSubscriptionAlert : null}
                invalid={formik.errors.description && formik.touched.description}
              />
              <div className="mt-1 justify-end" style={{ fontSize: "0.875rem", color: "#6c757d" }}>
                {desWordCount ? desWordCount : desWordCount1}/{business_descriptionlimit}
              </div>
              {formik.errors.description && formik.touched.description && (
                <p style={{ color: "red" }}>{formik.errors.description}</p>
              )}
            </Col>
            <span style={{ color: "red" }}>{descriPtioLimit}</span>
          </Row>
        </section>
      </Col>

      <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="8"
        validationFunction={handelValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
        emailAvialable={true}
        usernameAvailable={true}
      />

      <Popover
        placement="right"
        innerRef={popoverRef}
        isOpen={popoverOpen}
        toggle={togglePopover}
        target="UniqunessPopover"
        style={{ width: "400px", maxWidth: "100%" }}
      >
        <PopoverBody style={{ width: "100%", padding: "0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1px",
              width: "100%",
              minWidth: "100%",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p className="p-3">
              Make your mark by showcasing your unique talents. Let your skills shine on your profile and connect with
              those who value your creativity. Highlight your uniqueness on the landing page of our platform.
            </p>
          </div>
        </PopoverBody>
      </Popover>
    </Row>
  );
};

export default About;
