import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import NavigationHeader from "../components/NavigationHeader";
import { useLocation, useNavigate } from "react-router";
import BasicInfo from "./BasicInfo";
import Services from "./Services";
import Gallary from "./Gallary";
import Equipements from "./Equipements";
import PricingPackages from "./PricingPackages";
import SocialMedia from "./SocialMedia";
import Videos from "./Videos";
import About from "./About";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchPlanData, getCompanyProfile } from "../../http/get/getApi";
import { editCompanyDetails } from "../../http/edit/editApi";
import { CategoryScale } from "chart.js";
import { toast } from "react-toastify";
import Vendors from "./Vendors";
import { Icon } from "../../components/Component";
import { getChangedValues, getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import { DataContext } from "../../context/DataProvider";
import Mixes from "./Mixes";
import PackageListPage from "../Package/PackageListPage";
import VenderListPage from "../Vender/VenderListPage";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const CreateServiceProfile = () => {
  const useExampleContext = useContext(DataContext);
  // const queryClient = useQueryClient();
  const { setProfileProgress, profileprogress, hasUnsavedChanges, setHasUnsavedChanges, planEnddates,setPlanEndDates, activeTab, setActivetab, subscribedpalresponce, setSubscribedPalResponce} = useExampleContext;
  // navigation declrations
  const location = useLocation();
  // const navigate = useNavigate();

  const queryClient = useQueryClient();

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const page = searchParams.get("page");

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const isEditOnly = type === "edit" ? true : false;

  // const [activeTab, setActivetab] = useState("1");
  const [business_descriptionlimit, Setbusiness_description] = useState();

  const toggle = (number) => {
    setActivetab(number);
  };

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First name field is required."),
    last_name: Yup.string().required("Last name field is required."),
    city: Yup.string().required("City  field is required."),
    country: Yup.string().required("country  field is required."),
    state: Yup.string().required("state  field is required."),
    phone_number: Yup.string()
      .required("Work mobile no field is required.")
      .matches(/^[0-9]{9,13}$/, "Work mobile no must be between 9 to 13 digits."),
      personal_phone_number: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, "personal mobile no must be between 9 to 13 digits."),
    // files: Yup.string().required("Profile picture is required."),
    // // uniqueness: Yup.string().required("uniqueness field is required."),
    // uniqueness: Yup.string()
    //   .max(500, "Uniqueness cannot exceed 500 words."),
    // description: Yup.string()
    //   .test(
    //     "word-count",
    //     `Description cannot exceed ${business_descriptionlimit} words.`,
    //     function (value) {
    //       const wordCount = value ? value.trim().split(/\s+/).length : 0;
    //       return wordCount <= business_descriptionlimit;
    //     }
    //   ),
    // email: Yup.string().required("Email field is required.").email("Invalid email address."),
    zip_code: Yup.string()
    .nullable()
      .matches(/^[a-zA-Z0-9]{5,10}$/, "Zip-code must be at least 5 digits."),
    website: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    facebook: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    instagram: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    // twitter: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    tiktok: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    // skype: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    // snapchat: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    // telegram: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    // pinterest: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    linkedin: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    soundcloud: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    mixcloud: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
    twitch: Yup.string().url("Invalid URL format, Please Enter valid url format").nullable(true),
  });

  const [planEndDate, setPlanEndDate] = useState();
  const [photosLimit, setphotoslimit] = useState({});
  const [serviceList, SetService_list] = useState();
  const [videosLimit, setVideoslimit] = useState();
  const [youtubLinkLimit, setYoutubLinkLimit] = useState();
  
  // const [business_descriptionlimit, Setbusiness_description] = useState();
  const [mixersLimit, SetMixersLimit] = useState();
  const [SocialMediaUrls, SetSocialMediaUrls] = useState();
  
  let initialValues = {
    email: "",
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    phone_number: "",
    personal_phone_number: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    files: "",
    city: "",
    zip_code: "",
    services_offers: "",
    wedding: "",
    birthday: "",
    anniversary: "",
    engagement: "",
    corporate_event: "",
    clubs: "",
    concert: "",
    private_party: "",
    other: "",
    experience: "",
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube_links: [],
    link: "",
    title: "",
    currentYoutubeLink: "",
    description: "",
    tiktok: "",
    skype: "",
    snapchat: "",
    telegram: "",
    pinterest: "",
    uniqueness: "",
    category: [],
    services: [],
    genres: [],
    images: [],
    videos: [],
    mixes: [],
    mixes_links: [],
    mixlink: "",
    mixtitle: "",
    vendorName: "",
    mobileNumber: "",
    vendorType: "",
    vendors: [],
    mixres: "",
    desCount: "",
    uniquenessCount: "",
    experienceCount: "",
    websiteCount: "",
    facebookCount: "",
    instagramCount: "",
    twitterCount: "",
    tiktokCount: "",    
    skypeCount: "",
    snapchatCount: "",
    telegramCount: "",
    pinterestCount: "",
    nameCount: "",
    lastNameCount: "",
    contactNumberCount: "",
    personal_phone_number_count: "",
    address1Count: "",
    address2Count: "",
    zipCodeCount: "",
    imagePresent: "",
    linkedin: "",
    linkedinCount:"",
    soundcloud: "",
    soundcloudCount: "",
    mixcloud: "",
    mixcloudCount: "",
    twitch: "",
    twitchCount: "",
    latitude: "",
    longitude: "",
    latitudeCount: "",
    longitudeCount: "",
    mixesType: "",
  };
  


  // get company profile
  const {
    data: companyDetails,
    isLoading: companyprofileLoading,
    isError: companyprofileError,
  } = useQuery({
    queryKey: ["get-company-profile"],
    queryFn: () => getCompanyProfile(),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  // Mutation hook for editing Profile
  const {
    mutate: companyMutation,
    isLoading: isCompanyProfileEditing,
    error: companyProfileError,
  } = useMutation({
    mutationKey: ["edit-company-profile"],
    mutationFn: (value) => editCompanyDetails(value),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries(["get-company-profile"]);
      }

      if (data?.status == true) {
        Swal.fire({
          text: data?.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: data?.message,
          text: data?.errors,
          icon: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    onError: (error) => {
      toast.error("Failed to update company profile.");
    },
  });

  const editCompanyProfile = (value) => {
    try {
      companyMutation(value);
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  // useEffect(() => {
  //   subscribedPlanAPI();
  // }, [photosLimit, videosLimit, youtubLinkLimit, business_descriptionlimit, mixersLimit]);

  // const [subscribedpalresponce, setSubscribedPalResponce] = useState("")


  const {
    data: data1,
    isLoading: isLoading1,
    isError: isError1,
    refetch: refetchProfiledata,
  } = useQuery({
    queryKey: ["subscribePlanData"],
    queryFn: () => fetchPlanData(),
    enabled: false,
    // staleTime: Infinity,
    // staleTime: 50000,
    onSuccess: (responseData) => {
      setSubscribedPalResponce(responseData?.status);
        setPlanEndDates(responseData?.data?.package?.end_date);

        setphotoslimit(
          JSON.parse(
            responseData?.data?.package?.plan_data?.photos ? responseData?.data?.package?.plan_data?.photos : 0
          )
        );

        SetMixersLimit(
          JSON.parse(
            responseData?.data?.package?.plan_data?.mixes ? responseData?.data?.package?.plan_data?.mixes : 0
          )
        );

        Setbusiness_description( JSON.parse(responseData?.data?.package?.plan_data?.business_description
          ? responseData?.data?.package?.plan_data?.business_description
          : 0));

        setVideoslimit(
          JSON.parse(
            responseData?.data?.package?.plan_data?.videos ? responseData?.data?.package?.plan_data?.videos : 0
          )
        );

        SetService_list(
          JSON.parse(
            responseData?.data?.package?.plan_data?.service_list
              ? responseData?.data?.package?.plan_data?.service_list
              : 0
          )
        );

        const youtubeLinkData = responseData?.data?.package?.plan_data?.youtube_link;
        const youtubeLinkLimit =
          youtubeLinkData === "Unlimited" ? Infinity : JSON.parse(youtubeLinkData ? youtubeLinkData : 0);
        setYoutubLinkLimit(youtubeLinkLimit);
        // setYoutubLinkLimit(JSON.parse(responseData?.data?.package?.plan_data?.youtube_link ? responseData?.data?.package?.plan_data?.youtube_link : 0));

        const socialMediaUrlsData = responseData?.data?.package?.plan_data?.social_media_urls;
        const socialMediaUrls =
          socialMediaUrlsData === "Unlimited" ? Infinity : JSON.parse(socialMediaUrlsData ? socialMediaUrlsData : 0);

        SetSocialMediaUrls(socialMediaUrls);
    },
    
  });

  useEffect(()=>{
    refetchProfiledata();
  },[refetchProfiledata])


  // const subscribedPlanAPI = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //     });
     
  //     if (response.ok) {
  //       const responseData = await response.json();
  //       setSubscribedPalResponce(responseData.status)
  //       setPlanEndDates(responseData?.data?.package?.end_date);
        
  //       // setPlanEndDate(
  //       //   JSON.parse(
  //       //     responseData?.data?.package?.end_date ? responseData?.data?.package?.end_date : 0
  //       //   )
  //       // );

  //       setphotoslimit(
  //         JSON.parse(
  //           responseData?.data?.package?.plan_data?.photos ? responseData?.data?.package?.plan_data?.photos : 0
  //         )
  //       );

  //       SetMixersLimit(
  //         JSON.parse(
  //           responseData?.data?.package?.plan_data?.mixes ? responseData?.data?.package?.plan_data?.mixes : 0
  //         )
  //       );

  //       // const businessDescriptionString = responseData?.data?.package?.plan_data?.business_description
  //       //   ? responseData?.data?.package?.plan_data?.business_description
  //       //   : 0;
  //       // const parsedDescription = JSON.parse(businessDescriptionString);
  //       // const businessValue = parsedDescription;

  //       // Setbusiness_description(businessValue);
  //       Setbusiness_description( JSON.parse(responseData?.data?.package?.plan_data?.business_description
  //         ? responseData?.data?.package?.plan_data?.business_description
  //         : 0));

  //       setVideoslimit(
  //         JSON.parse(
  //           responseData?.data?.package?.plan_data?.videos ? responseData?.data?.package?.plan_data?.videos : 0
  //         )
  //       );

  //       SetService_list(
  //         JSON.parse(
  //           responseData?.data?.package?.plan_data?.service_list
  //             ? responseData?.data?.package?.plan_data?.service_list
  //             : 0
  //         )
  //       );

  //       const youtubeLinkData = responseData?.data?.package?.plan_data?.youtube_link;
  //       const youtubeLinkLimit =
  //         youtubeLinkData === "Unlimited" ? Infinity : JSON.parse(youtubeLinkData ? youtubeLinkData : 0);
  //       setYoutubLinkLimit(youtubeLinkLimit);
  //       // setYoutubLinkLimit(JSON.parse(responseData?.data?.package?.plan_data?.youtube_link ? responseData?.data?.package?.plan_data?.youtube_link : 0));

  //       const socialMediaUrlsData = responseData?.data?.package?.plan_data?.social_media_urls;
  //       const socialMediaUrls =
  //         socialMediaUrlsData === "Unlimited" ? Infinity : JSON.parse(socialMediaUrlsData ? socialMediaUrlsData : 0);

  //       SetSocialMediaUrls(socialMediaUrls);
  //       // SetSocialMediaUrls(JSON.parse(responseData?.data?.package?.plan_data?.social_media_urls ? responseData?.data?.package?.plan_data?.social_media_urls : 0 ));
  //     } else {
  //       throw new Error("Failed to fetch plan data");
  //     }
  //     return response;
  //   } catch (error) {
  //     console.error("Error fetching plan data:", error);
  //   }
  // };

  const [videoCount, SetvideoCount] = useState();
  const [mixesCount, SetMixesCount] = useState();
  const [descriptioncount, SetDescriPtionCount] = useState();
  const [imagecount, Setimagecount] = useState();
  const [youtubelinkCount, SetYoutubeLinkCount] = useState();
  const [serviceCount, SetServiceCount] = useState(0);
  const [socialMediaCount, setSocialMediaCount] = useState(0);
  const [vendorCount, setVendorCount] = useState();

  useEffect(() => {
    if (!companyprofileLoading) {
      formik.setValues((prevData) => {
        const parseStringifiedObject = (stringifiedObject) => {
          if (!stringifiedObject) return {};

          try {
            return JSON.parse(stringifiedObject);
          } catch (error) {
            console.error("Error parsing stringified object:", error);
            return {};
          }
        };


        const parsedVendors = JSON.parse(companyDetails?.data?.profile?.vendor || "[]");
        setVendorCount(parsedVendors.length ? parsedVendors.length : 0);
        const youtubeLinks = JSON.parse(companyDetails?.data?.profile?.youtube_links || "[]");
        const mixesLinks = JSON.parse(companyDetails?.data?.profile?.mixes_links || "[]");
        // const parsedVideos = parseResponseData(companyDetails?.data?.profile?.videos);
        // const parsedMixes = parseResponseData(companyDetails?.data?.profile?.mixres);
        Setimagecount(JSON.parse(companyDetails?.data?.profile?.image_count || "[]"));
        SetvideoCount(JSON.parse(companyDetails?.data?.profile?.video_count || "0"));
        SetMixesCount(JSON.parse(companyDetails?.data?.profile?.mixres_count || "0"));
        const descriptionCount = JSON.parse(companyDetails?.data?.profile?.description_count || "0");
        SetDescriPtionCount(descriptionCount);
        setSocialMediaCount(JSON.parse(companyDetails?.data?.profile?.social_media_count || "0"));
        SetYoutubeLinkCount(JSON.parse(companyDetails?.data?.profile?.youtube_link_count || "0"));
        const servicelength = companyDetails?.data?.profile?.services ? companyDetails?.data?.profile?.services : "0";
        SetServiceCount(Object.keys(servicelength).length);

        setProfileProgress(
          JSON.parse(
            companyDetails?.data?.profile?.profile_progress ? companyDetails?.data?.profile?.profile_progress : 0
          )
        );
        return {
          ...prevData,
          ...companyDetails?.data?.profile,
          username: companyDetails?.data?.username,
          email: companyDetails?.data?.email,
          country: parseInt(companyDetails?.data?.profile?.country),
          state: parseInt(companyDetails?.data?.profile?.state),
          city: parseInt(companyDetails?.data?.profile?.city),
          latitude: parseFloat(companyDetails?.data?.profile?.latitude),
          longitude: parseFloat(companyDetails?.data?.profile?.longitude),
          // images: parseImages(companyDetails?.data?.profile?.images),
          images: companyDetails?.data?.profile?.images,
          videos: companyDetails?.data?.profile?.videos || [],
          files: companyDetails?.data?.profile?.profile_image_path,
          category: companyDetails?.data?.profile?.category,
          services: companyDetails?.data?.profile?.services,
          genres: companyDetails?.data?.profile?.genres, // category: parseStringifiedObject(companyDetails?.data?.profile?.category),
          // services: parseStringifiedObject(companyDetails?.data?.profile?.services),
          // genres: parseStringifiedObject(companyDetails?.data?.profile?.genres),
          vendors: parsedVendors,
          mixes: companyDetails?.data?.profile?.mixres || [],
          youtube_links: youtubeLinks,
          mixes_links: mixesLinks,
        };
      });
    }
  }, [companyprofileLoading, videoCount, companyDetails, youtubelinkCount]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      // editCompanyProfile(value);
      editCompanyProfile(getChangedValues(companyDetails?.data?.profile, formik?.values));
    },
  });


  // const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // console.log("hasUnsavedChanges",hasUnsavedChanges)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <>
      <NavigationHeader
        heading={id == "null" ? "Add Profile" : "Edit Profile"}
        buttonInnerText={"Profile"}
        route={"/Company-profile"}
        back
      />
      <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              <Icon name="user" />
              <span> Basic Information</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              <Icon name="info" />
              <span> About</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              <Icon name="headphone" />
              <span> Services</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("4");
              }}
            >
              <Icon name="play-circle" />
              <span> Mixes</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "5" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("5");
              }}
            >
              <Icon name="img" />
              <span> Gallery</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "6" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("6");
              }}
            >
              <Icon name="video" />
              <span> Videos</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "7" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("7");
              }}
            >
              <Icon name="browser" />
              <span> Social Media</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "8" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("8");
              }}
            >
              <Icon name="user-list" />
              <span> Vendor</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "9" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("9");
              }}
            >
              <Icon name="package" />
              <span>Package List</span>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "5" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("5");
              }}
            >
              Pricing
            </NavLink>
          </NavItem> */}
          {/* <NavItem>
            <NavLink
              // disabled
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "6" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("6");
              }}
            >
              Social Media
            </NavLink>
          </NavItem> */}
        </Nav>
        <form onSubmit={formik.handleSubmit} className="mt-3">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <BasicInfo
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                companyprofileLoading={companyprofileLoading}
                formik={formik}
              />
            </TabPane>
            <TabPane tabId="2">
              <About
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                business_descriptionlimit={business_descriptionlimit}
                descriptioncount={descriptioncount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            <TabPane tabId="3">
              <Services
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                mixersLimit={mixersLimit}
                serviceList={serviceList}
                serviceCount={serviceCount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            {/* mixes */}
            <TabPane tabId="4">
              <Mixes
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                mixersLimit={mixersLimit}
                mixesCount={mixesCount}
                subscribedpalresponce={subscribedpalresponce}
                planEndDate={planEndDate}
              />
            </TabPane>
            <TabPane tabId="5">
              <Gallary
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                photosLimit={photosLimit}
                imagecount={imagecount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            <TabPane tabId="6">
              <Videos
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                videosLimit={videosLimit}
                youtubLinkLimit={youtubLinkLimit}
                videoCount={videoCount}
                youtubelinkCount={youtubelinkCount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            <TabPane tabId="7">
              <SocialMedia
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                SocialMediaUrls={SocialMediaUrls}
                socialMediaCount={socialMediaCount}
                subscribedpalresponce={subscribedpalresponce}
              />
            </TabPane>
            <TabPane tabId="8">
              <VenderListPage
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                vendorCount={vendorCount}
              />
            </TabPane>
            <TabPane tabId="9">
              <PackageListPage
                toggle={toggle}
                currentState={activeTab}
                isCompanyProfileEditing={isCompanyProfileEditing}
                formik={formik}
                vendorCount={vendorCount}
              />
            </TabPane>
            {/* <TabPane tabId="4">
              <Equipements toggle={toggle} currentState={activeTab} formik={formik} />
            </TabPane> */}
            {/* <TabPane tabId="5">
              <PricingPackages toggle={toggle} currentState={activeTab} formik={formik} />
            </TabPane>
            <TabPane tabId="6">
              <SocialMedia toggle={toggle} currentState={activeTab} formik={formik} />
            </TabPane> */}
          </TabContent>
        </form>
      </section>
    </>
  );
};
export default CreateServiceProfile;
