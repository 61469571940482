import React, { useContext } from "react";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Row } from "reactstrap";
import { getVenueList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import NavigationHeader from "../components/NavigationHeader";
import { DataContext } from "../../context/DataProvider";

function PreviewVenueDetails() {
  const { currency, setCurrency } = useContext(DataContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");
  

  const {
    data: venueData,
    isLoading: venueListLoading,
    isError: packageListIsError,
  } = useQuery({
    queryKey: ["get-venue-by-id", id],
    queryFn: () => getVenueList({ id: id }),
  });

  const client = venueData?.data?.list?.[0];
  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Venue Detail" : ""}
        buttonInnerText={"Back"}
        route={"/venue-list"}
        back
      />
      {venueListLoading ? (
        <Row className="py-0 m-4">
          {/* Left column with image */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col md={8} className="g-4">
                <Row className="mt-2">
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                    <ShimmerText height={20} width={150} />
                {/* <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Col>

          {/* Right column with details */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col sx={12} md={6} className="border p-3 bg-white g-3">
              <Row className="mt-3">
                <Col xs={6} md={4}>
                  <strong>Venue Name :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.venue_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Address :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.address || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Zip-code :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.zip_code || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Country :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.country || "N/A"} */}
                  {client?.countryName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>State :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.state || "N/A"} */}
                  {client?.stateName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>City :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {/* {client?.city || "N/A"} */}
                  {client?.cityName || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Notes :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {client?.notes || "N/A"}
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col xs={12} md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={4}>
                  <strong>Email :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.email_address || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Mobile No :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Office Telephone :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.office_telephone || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Distance :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.distance ? `${client?.distance} miles` : "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Travel cost :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.travel_cost ? `${currency} ${client?.travel_cost}` : "N/A"}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewVenueDetails;
