import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Input, Label, Row } from "reactstrap";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import { parseDateString } from "../../../utils/Utils";
import NavigationFooter from "../NavigationFooter";
import Required from "../Required";
import { toast } from "react-toastify";
import { getEmployeeList, getsitesetting } from "../../../http/get/getApi";
import { useQuery } from "react-query";
import { DataContext } from "../../../context/DataProvider";

const EmploymentAndWages = ({ toggle, currentState, formik, isViewOnly, isEmployeeEditing }) => {

  const { currency, setCurrency } = useContext(DataContext);

  const employmentTypeOptions = [
    { value: "1", label: "Individual" },
    { value: "2", label: "Full-time" },
    { value: "3", label: "Part-time" },
    { value: "4", label: "Contractor" },
    { value: "5", label: "Freelancer" },
  ];

  const roles = [
    { value: "1", label: "Wedding DJ" },
    { value: "2", label: "Club DJ" },
    { value: "3", label: "Event DJ" },
    { value: "4", label: "Radio DJ" },
    { value: "5", label: "Karaoke DJ" },
  ];

  const currentYear = new Date().getFullYear();
  const yearsOptions = [];

  for (let year = 2000; year <= currentYear; year++) {
    yearsOptions.push({ value: year, label: year.toString() });
  }

  const calculationMethodOptions = [
    { value: "1", label: "Beat Mixing" },
    { value: "2", label: "Scratching" },
    { value: "3", label: "Sampling" },
    { value: "4", label: "Remixing" },
  ];

  const handelEmployeeTypeDropdown = (e) => {
    formik.setFieldValue("emp_type", e.value);
  };

  const handelEmployeeRoleDropdown = (e) => {
    formik.setFieldValue("emp_category", e.value);
  };

  const handelStartYearDropdown = (e) => {
    formik.setFieldValue("began_profession", e.value);
  };

  const handelCalculationMethodDropdown = (e) => {
    formik.setFieldValue("calculation_method", e.value);
  };

  const handelValidation = (callbackFun) => {
    // if (formik.values.emp_number === "") {
    //   toast.error("Employee ID is required.");
    //   return;
    // }

    // if (formik.values.start_hired_date === "") {
    //   toast.error("Hired Date is required.");
    //   return;
    // }

    // if (formik.values.emp_type === "") {
    //   toast.error("Employment Type is required.");
    //   return;
    // }

    // if (formik.values.emp_category === "") {
    //   toast.error("Employee Role is required.");
    //   return;
    // }

    // if (formik.values.began_profession === "") {
    //   toast.error("Start Year is required.");
    //   return;
    // }

    // if (formik.values.no_event_done === "") {
    //   toast.error("Number of Employees is required.");
    //   return;
    // }

    // if (formik.values.emp_experience === "") {
    //   toast.error("Experience is required.");
    //   return;
    // }

    // if (formik.values.emp_pay_rate === "") {
    //   toast.error("Payrate is required.");
    //   return;
    // }

    // if (formik.values.calculation_method === "") {
    //   toast.error("Calculation Method is required.");
    //   return;
    // }

    // if (formik.values.hourly_rate === "") {
    //   toast.error("Hourly Rate is required.");
    //   return;
    // }

    callbackFun(true);
  };

  //event listener  to stop the scrolling behavior
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number" && document.activeElement.classList.contains("noscroll")) {
      document.activeElement.blur();
    }
  });

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== '') {
        wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
};


  return (
    <>
      <Row className="p-2 py-0">
        {/* First Section */}
        <Col md={6} className="border p-3 bg-white">
          <Row className="g-2">
            <Col md={6}>
              <div>
                <Label for="emp_number">Employee ID Number</Label>
                <Input
                  readOnly={isViewOnly}
                  type="text"
                  maxLength={9}
                  className="noscroll"
                  id="emp_number"
                  placeholder=" Employee id"
                  name="emp_number"
                  onKeyDown={(evt) => ["e", "E", "+"].includes(evt.key) && evt.preventDefault()}
                  value={formik.values.emp_number}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('emp_number', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.emp_number && formik.touched.emp_number && (
                  <p style={{ color: "red" }}> {formik.errors.emp_number}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="start_hired_date">Start/Hired Date</Label>
                <DatePicker
                  readOnly={isViewOnly}
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText=" Start/hired date"
                  id="start_hired_date"
                  dateFormat={"dd/MM/yy"}
                  maxDate={new Date() - 1}
                  selected={formik.values.start_hired_date ? new Date(formik.values.start_hired_date) : ""}
                  onChange={(date) => formik.setFieldValue("start_hired_date", date ? date.toISOString() : "")}
                  className="form-control date-picker"
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.start_hired_date && formik.touched.start_hired_date && (
                  <p style={{ color: "red" }}> {formik.errors.start_hired_date}</p>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4">
              <div>
                <Label for="employmentTypeOptions">Employment Type</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={employmentTypeOptions}
                  id="emp_type"
                  name="emp_type"
                  placeholder={"Select Type"}
                  value={formik.values.emp_type || null}
                  onChange={(e) => handelEmployeeTypeDropdown(e)}
                  // onChange={(e) => handleInputChange('emp_type', e)}
                  onBlur={() => formik.setFieldTouched("emp_type", true)}
                />
                {formik.errors.emp_type && formik.touched.emp_type && ( 
                  <p style={{ color: "red" }}> {formik.errors.emp_type}</p>
                )}
              </div>
            </Col>
            <Col md={4} className="mt-4">
              <div>
                <Label for="roles">Employee Role/Category</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={roles}
                  id="roles"
                  placeholder={"Select Role"}
                  name="emp_category"
                  value={formik.values.emp_category || null}
                  onChange={(e) => handelEmployeeRoleDropdown(e)}
                  onBlur={() => formik.setFieldTouched("emp_category", true)}
                />
                {formik.errors.emp_category && formik.touched.emp_category && (
                  <p style={{ color: "red" }}> {formik.errors.emp_category}</p>
                )}
              </div>
            </Col>
            <Col md={4} className="mt-4">
              <div>
                <Label for="began_profession">Began Profession in</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={yearsOptions}
                  id="began_profession"
                  name="began_profession"
                  placeholder={"Select Profession"}
                  value={formik.values.began_profession || null}
                  onChange={(e) => {
                    handelStartYearDropdown(e);
                    const currentYear = new Date().getFullYear();
                    const experience = currentYear - parseInt(e.value);
                    formik.setFieldValue("emp_experience", experience);
                  }}
                  onBlur={() => formik.setFieldTouched("began_profession", true)}
                />
                {formik.errors.began_profession && formik.touched.began_profession && (
                  <p style={{ color: "red" }}> {formik.errors.began_profession}</p>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-2 g-2">
            <Col md={6}>
              <div>
                <Label for="no_event_done">No Of Events Done</Label>
                <Input
                  readOnly={isViewOnly}
                  className="noscroll"
                  id="no_event_done"
                  placeholder="Enter number of events done"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  name="no_event_done"
                  value={formik.values.no_event_done}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('no_event_done', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.no_event_done && formik.touched.no_event_done && (
                  <p style={{ color: "red" }}> {formik.errors.no_event_done}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="emp_experience">Employee Experience</Label>
                <Input
                  readOnly={isViewOnly}
                  disabled
                  id="emp_experience"
                  placeholder="Enter experience"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  name="emp_experience"
                  value={formik.values.emp_experience}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('emp_experience', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.emp_experience && formik.touched.emp_experience && (
                  <p style={{ color: "red" }}> {formik.errors.emp_experience}</p>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        {/* Second Section */}
        <Col md={6} className="border p-3 bg-white border-start-0">
          <Row className="g-2">
            <Col md={6}>
              <div>
                <Label for="emp_pay_rate">Employee Pay Rate</Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span className="input-group-text" id="basic-addon1">
                      $
                    </span> */}
                    <span className="input-group-text" id="basic-addon1">
                      {currency}
                    </span>
                  </div>
                <Input
                  readOnly={isViewOnly}
                  type="number"
                  className="noscroll"
                  id="emp_pay_rate"
                  placeholder="Enter employee pay rate"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  name="emp_pay_rate"
                  value={formik.values.emp_pay_rate}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('emp_pay_rate', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                </div>
                {formik.errors.emp_pay_rate && formik.touched.emp_pay_rate && (
                  <p style={{ color: "red" }}> {formik.errors.emp_pay_rate}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
            <Label for="hourly_rate">Hourly Rate</Label>
              <div className="form-control-wrap">
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/* <span className="input-group-text" id="basic-addon1">
                      $
                    </span> */}
                    <span className="input-group-text" id="basic-addon1">
                      {currency}
                    </span>
                  </div>
                  <Input
                    readOnly={isViewOnly}
                    type="number"
                    id="hourly_rate"
                    className="form-control noscroll"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    placeholder="Enter hourly rate"
                    name="hourly_rate"
                    value={formik.values.hourly_rate}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('hourly_rate', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.errors.hourly_rate && formik.touched.hourly_rate && (
                    <p style={{ color: "red" }}> {formik.errors.hourly_rate}</p>
                  )}
                </div>
              </div>
              {/* <div>
                <Label for="calculationMethodOptions">Select Calculation Method</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={calculationMethodOptions}
                  id="calculation_method"
                  name="calculation_method"
                  value={formik.values.calculation_method}
                  onChange={(e) => handelCalculationMethodDropdown(e)}
                  onBlur={() => formik.setFieldTouched("calculation_method", true)}
                />
                {formik.errors.calculation_method && formik.touched.calculation_method && (
                  <p style={{ color: "red" }}> {formik.errors.calculation_method}</p>
                )}
              </div> */}
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col md={12}>
              <Label for="hourly_rate">Hourly Rate</Label>
              <div className="form-control-wrap">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                  </div>
                  <Input
                    readOnly={isViewOnly}
                    type="number"
                    id="hourly_rate"
                    className="form-control noscroll"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    placeholder="Enter hourly rate"
                    name="hourly_rate"
                    value={formik.values.hourly_rate}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('hourly_rate', e)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.hourly_rate && formik.touched.hourly_rate && (
                    <p style={{ color: "red" }}> {formik.errors.hourly_rate}</p>
                  )}
                </div>
              </div>
            </Col>
          </Row> */}
        </Col>
      </Row>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage={"4"}
          formik={formik}
          validationFunction={handelValidation}
          isEmployeeEditing={isEmployeeEditing}
          emailAvialable={true}
          usernameAvailable={true}
        />
      )}
    </>
  );
};
export default EmploymentAndWages;
