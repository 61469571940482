import React, { useContext, useState, useEffect } from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Col, Input, Row } from "reactstrap";
import { Icon } from "../../components/Component";
import { DataContext } from "../../context/DataProvider";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import subscribeimg from "../../../src/assets/images/subscribe.jpg";
import { AiFillTikTok } from "react-icons/ai";
import { FaMixcloud } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import { toast } from "react-toastify";

const SocialMedia = ({
  currentState,
  formik,
  toggle,
  isCompanyProfileEditing,
  SocialMediaUrls,
  socialMediaCount,
  subscribedpalresponce,
}) => {
  const [limitReached, setLimitReached] = useState(false);
  const { setHasUnsavedChanges, activeTab, setActivetab } = useContext(DataContext);
  const navigate = useNavigate();
  const handelValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== ""
    ) {

      if( ((formik.values.facebook) && (!formik.values.facebook.startsWith("https") && !formik.values.facebook.startsWith("http") )) ||  ((formik.values.website) && (!formik.values.website.startsWith("https") && !formik.values.website.startsWith("http") )) || ((formik.values.instagram) && (!formik.values.instagram.startsWith("https") && !formik.values.instagram.startsWith("http") )) || ((formik.values.tiktok) && (!formik.values.tiktok.startsWith("https") && !formik.values.tiktok.startsWith("http"))) || ((formik.values.soundcloud) && (!formik.values.soundcloud.startsWith("https") && !formik.values.soundcloud.startsWith("http") )) || ((formik.values.mixcloud) && (!formik.values.mixcloud.startsWith("https") && !formik.values.mixcloud.startsWith("http") )) || ((formik.values.linkedin) && (!formik.values.linkedin.startsWith("https") && !formik.values.linkedin.startsWith("http") )) || ((formik.values.twitch) && (!formik.values.twitch.startsWith("https") && !formik.values.twitch.startsWith("http") )) ){
        toast.error("Please Enter valid url format");
      }else{
        callbackFun(true);
      }
    } else {
      callbackFun(false);
      toast.error("Please Fill Basic Info Details");
    }
  };

  // Effect to handle initial disabling of empty fields
  useEffect(() => {
    if (socialMediaCount >= SocialMediaUrls) {
      setLimitReached(true);
    }
  }, [socialMediaCount, SocialMediaUrls]);

  const countFilledSocialMediaFields = () => {
    const fields = [
      "website",
      "facebook",
      "linkedin",
      "instagram",
      "tiktok",
      "soundcloud",
      "mixcloud",
      "twitch",
      // "twitter",
      // "skype",
      // "snapchat",
      // "telegram",
      // "pinterest",
    ];

    const filledFields = fields.filter((field) => {
      const value = formik.values[field];
      return typeof value === "string" && value.trim() !== "";
    });

    return filledFields.length;
  };
  const [totalCounts, setTotalCounts] = useState();
  // console.log("totalCounts",totalCounts)
  const sosicalMediahandlechange = (e) => {
    const filledCount = countFilledSocialMediaFields();
    const totalCount = filledCount + socialMediaCount;
    setTotalCounts(totalCount);
    // Update limitReached state based on filledCount
    if (totalCount >= SocialMediaUrls) {
      e.preventDefault();
      setLimitReached(true);
      Swal.fire({
        title: "Social Media URL Limit Exceeded!",
        text: `You've reached the limit of ${SocialMediaUrls} Social Media URLs. Upgrade your plan to add more Social Media URLs and make your business stand out!`,
        icon: "warning",
        confirmButtonText: "Upgrade Plan Now!",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
          setActivetab("7");
        }
      });
    } else {
      setLimitReached(false);
    }

    // Allow formik to update values if below the limit
    formik.handleChange(e);
  };

  const handleSubscriptionAlert = () => {
    Swal.fire({
      title: "Subscription Required",
      text: "Subscribe to one of our plans and enjoy the ability to add more Social Media URLs to your account.",
      imageUrl: subscribeimg,
      imageWidth: 250,
      imageHeight: 200,
      imageAlt: "Custom image",
      showCancelButton: true,
      confirmButtonText: "Subscribe Now",
      cancelButtonText: "Maybe Later",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/plan-selection");
      }
    });
  };

  const handleSocialMediaChange = (field, e) => {
    const { value } = e.target;
    let wordCount = 0;

    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    formik.setFieldValue(`${field}Count`, wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  return (
    <Row className="p-2 py-0 mt-2">
      <Col md={6} className="border p-3 bg-white">
        <section>
          {[
            { id: "website", icon: "globe", placeholder: "Enter website link" },
            { id: "facebook", icon: "facebook-fill", placeholder: "Enter facebook link" },
            { id: "linkedin", icon: "linkedin-round", placeholder: "Enter LinkedIn link" },
            { id: "instagram", icon: "instagram-round", placeholder: "Enter instagram link" },
            // { id: "twitter", icon: "twitter-round", placeholder: "Enter twitter link" },
            { id: "tiktok", icon: "AiFillTikTok", placeholder: "Enter TikTok link" },
          ].map((field) => (
            <Row key={field.id} className="mt-4">
              <Col md={12}>
                <div className="form-control-wrap">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        {field.icon === "AiFillTikTok" ? (
                          <AiFillTikTok style={{ fontSize: "20px" }} />
                        ) : (
                          <Icon name={field.icon} style={{ fontSize: "20px" }} />
                        )}
                      </span>
                    </div>
                    <Input
                      type="text"
                      id={field.id}
                      name={field.id}
                      placeholder={field.placeholder}
                      value={formik.values[field.id]}
                      onChange={(e) => {
                        sosicalMediahandlechange(e);
                        handleSocialMediaChange(field.id, e);
                      }}
                      onKeyDown={!subscribedpalresponce ? handleSubscriptionAlert : null}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors[field.id] && formik.touched[field.id]}
                      disabled={limitReached && !formik.values[field.id]?.trim()}
                    />
                  </div>
                  {formik.errors[field.id] && formik.touched[field.id] && (
                    <p style={{ color: "red" }}>{formik.errors[field.id]}</p>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </section>
      </Col>

      <Col md={6} className="border p-3 bg-white border-start-0">
        <section>
          {[
            { id: "soundcloud", icon: "soundcloud", placeholder: "Enter Soundcloud link" },
            { id: "mixcloud", icon: "FaMixcloud", placeholder: "Enter MixCloud link" },
            { id: "twitch", icon: "FaTwitch", placeholder: "Enter Twitch link" },
            // { id: "skype", icon: "skype", placeholder: "Enter Skype link" },
            // { id: "snapchat", icon: "snapchat", placeholder: "Enter snapchat link" },
            // { id: "telegram", icon: "telegram", placeholder: "Enter Telegram link" },
            // { id: "pinterest", icon: "pinterest-round", placeholder: "Enter Pinterest link" },
          ].map((field) => (
            <Row key={field.id} className="mt-4">
              <Col md={12}>
                <div className="form-control-wrap">
                  <div className="input-group">
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                        {field.icon === "FaMixcloud" ? (
                          <FaMixcloud style={{ fontSize: "20px" }} />
                        ) : field.icon === "FaTwitch" ? (
                          <FaTwitch style={{ fontSize: "20px" }} />
                        )
                         : (
                          <Icon name={field.icon} style={{ fontSize: "20px" }} />
                        )}
                      </span>
                    </div>
                    <Input
                      type="text"
                      id={field.id}
                      name={field.id}
                      placeholder={field.placeholder}
                      value={formik.values[field.id]}
                      onChange={(e) => {
                        sosicalMediahandlechange(e);
                        handleSocialMediaChange(field.id, e);
                      }}
                      onKeyDown={!subscribedpalresponce ? handleSubscriptionAlert : null}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors[field.id] && formik.touched[field.id]}
                      disabled={limitReached && !formik.values[field.id]?.trim()}
                    />
                  </div>
                  {formik.errors[field.id] && formik.touched[field.id] && (
                    <p style={{ color: "red" }}>{formik.errors[field.id]}</p>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </section>
      </Col>
      <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="8"
        validationFunction={handelValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
        emailAvialable={true}
        usernameAvailable={true}
      />
    </Row>
  );
};

export default SocialMedia;
