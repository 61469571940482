import React, { useContext, useEffect, useState } from "react";
import NavigationHeader from "../components/NavigationHeader";
import { useQuery } from "react-query";
import { getAvailableSlot, getEventsList, getSlotAvailability } from "../../http/get/getApi";
import Calender from "../components/Calender";
import { PlanContext } from "../context/PlanContext";

function CalenderComponent() {

  const { geteventdata, getTransformSlotdata, setGetEventData, setTransformSlotdata } = useContext(PlanContext);

  const {
    data: events,
    isLoading,
    isError,
    refetch: refetchEvents,
  } = useQuery({
    queryKey: ["get-events"],
    queryFn: () => getEventsList({ id: "", page: "", limit: "", search: "" }),
    enabled: false,
  });

   const eventsData = Array.isArray(events?.data)
    ? events.data
        .map((event) => {
          if (!event || !event.start_date_time || !event.end_date_time || !event.event_name || !event.event_type_name) {
            return null;
          }

          const startDate = new Date(event.start_date_time).toISOString().split("T")[0];
          const endDate = new Date(event.end_date_time).toISOString().split("T")[0];

          return {
            id: "default-event-id-" + event.id,
            title: event.event_name,
            start: startDate,
            end: endDate,
            description: event.event_type_name,
          };
        })
        .filter((event) => event !== null)
    : [];

  const {
    data: availableSlots,
    isLoading: availableSlotLoading,
    isError: availableSlotError,
    refetch: refetchAvailableSlots,
  } = useQuery({
    queryKey: ["get-available-slot"],
    queryFn: () => getAvailableSlot(),
    enabled: false,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
  });

  
  useEffect(() => {
    refetchAvailableSlots();  // Manually refetch available slots
    refetchEvents();          // Manually refetch events
  }, [refetchAvailableSlots]); 

  const transformedAvailableSlots = Array.isArray(availableSlots?.data)
    ? availableSlots?.data?.map((slot) => ({
        id: slot.id,
        start_date: slot.start_date,
        start_time: slot.start_time,
        end_date: slot.end_date,
        end_time: slot.end_time,
        recurring: slot.recurring,
        interval: slot.interval,
      }))
    : [];
  // console.log("transformedAvailableSlots", transformedAvailableSlots);

  return (
    <div>
      <NavigationHeader heading={"Calendar"} buttonInnerText={""} />
      <Calender events1={eventsData} selectedavailableSlot={transformedAvailableSlots} />
    </div>
  );
}

export default CalenderComponent;
