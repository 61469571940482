import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { DataContext } from "../../../context/DataProvider";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";

 function CheckoutForm({ selectedPlan, setModalData, invalidPlanData, setModal, modal, fetchPlanData, setModalTab, modalTab}) {
  const { newPlanId, transactionid} = useContext(DataContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/plan-selection",
        return_url: `${process.env.REACT_APP_API_URL}plan-selection`,
    },
    redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else if(paymentIntent.status == "succeeded"){
    //     console.log("paymentIntent", paymentIntent)
    // }else {
    //   setMessage("An unexpected error occurred.");
    // }

    if (error?.type === "card_error" || error?.type === "validation_error") {
          setMessage(error.message);
    }else if (paymentIntent && paymentIntent?.status === "succeeded") {
      
      console.log("paymentIntent",paymentIntent)
        try {
            handleSubmitSubscribePayment(newPlanId);

            
        
        } catch (err) {
          console.error("Error subscribing to plan:", err);
        }
      }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  const handleSubmitSubscribePayment = async (newPlanId) => {
    const plan_id = selectedPlan?.id ? selectedPlan?.id : newPlanId;
    const note = selectedPlan?.name + " plan purchased";

    
    try {
      const formData = new FormData();
      formData.append("status", "3");
      formData.append("payment_txn_id", transactionid);
      formData.append("payment_response", "payment_response");
      formData.append("amount", selectedPlan?.discount_price ? selectedPlan?.discount_price : selectedPlan?.price);
      formData.append("payer_id", "1");
      formData.append("payment_mode", "3");
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("mode", "3");
      formData.append("plan_id", plan_id);
      // const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-package`, {
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/subscribe-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (planData && planData.status) {
        const submittedData = planData?.data;
        const planData1 = await planData.json();
        setModalData(planData1);
        toast.success("Plan Purchased Successfully");
        invalidPlanData();
        setTimeout(() => {
          setModal(!modal);
          fetchPlanData();
          setModalTab(!modalTab);
        }, 1000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      
    }

  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>

        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="stripebutton">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      {/* [DEV]: Display dynamic payment methods annotation and integration checker */}
      <div id="dpm-annotation">
        {/* <p>
          <a className="" href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">Preview payment methods by transaction</a>
        </p> */}
      </div>
    </>
  );
}

export default CheckoutForm;