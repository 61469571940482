import { Col, Label, Row } from "reactstrap";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import NavigationFooter from "../NavigationFooter";
import Required from "../Required";
import { getEmployeeList } from "../../../http/get/getApi";

const Level = ({ toggle, currentState, formik, isEmployeeAdding, isEmployeeEditing, isViewOnly }) => {
  const existingClient = [
    {
      label: "Administrator",
      value: "1",
    },
    {
      label: "Employee",
      value: "2",
    },
    {
      label: "Salesperson",
      value: "3",
    },
  ];

  function handleExistingClient(e) {
    formik.setFieldValue("emp_designation", e.value);
  }

  const handelValidation = (callbackFun) => {
    callbackFun(true);
  };

  return (
    <>
      <div>
        <div className="tab-content">
          <Row className="row">
            <Col sm="6" lg="8">
              <Label for="employeeLevel">Select From Existing</Label>
              <ReactSelect
                disabled={isViewOnly}
                options={existingClient}
                id="emp_designation"
                name="emp_designation"
                placeholder={"Select Employeement Level"}
                value={formik?.values?.emp_designation || null}
                onChange={(e) => handleExistingClient(e)}
                onBlur={() => formik.setFieldTouched("emp_designation", true)}
              />
              {formik.errors.status && formik.touched.status && <p style={{ color: "red" }}> {formik.errors.status}</p>}
            </Col>
          </Row>
        </div>
      </div>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage={"4"}
          formik={formik}
          validationFunction={handelValidation}
          isEmployeeEditing={isEmployeeEditing}
          navigateRoute={"/employee-list"}
          emailAvialable={true}
          usernameAvailable={true}
        />
      )}
    </>
  );
};
export default Level;
