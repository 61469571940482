import React, { useEffect, useState } from "react";
import { CardImg, Col, Row } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ProjectCard,
  TooltipComponent,
} from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Top_3_Events from '../../../src/assets/images/Top 3 Events.png'

// let token = process.env.REACT_APP_AUTH_TOKEN;

const TotalWages = ({allData, isLoadingallData}) => {
  const [eventData, setEventData] = useState([]);
  const [isEventPresent, setIsEventPresent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allNewData, setAllNewData]= useState()

  const eventCountsArrayOfObjects = Object.entries(allNewData || {}).map(([key, value]) => ({
    eventType: key,
    count: value
}));


useEffect(()=>{
  setAllNewData(allData?.data?.totalEventCounts)
},[allData])

  // useEffect(() => {
  //   eventInfo();
  // }, []);

  // const parseDateString = (date) => new Date(date).toISOString();

  // const eventInfo = async () => {
  //   setLoading(true);
  //   try {
  //     // const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event-count`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //         // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const responseData = await response.json();
  //     if (!responseData.status) {
  //       setIsEventPresent(false);
  //       return;
  //     }

  //     setEventData(responseData|| []);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const getTop3Events = (data = {}, totalEventsCount = {}) => {
  //   if (!data || !totalEventsCount) {
  //     return [];
  //   }
  
  //   const eventArray = Object.keys(totalEventsCount).map(eventName => ({
  //     eventName,
  //     completedCount: data[eventName] || 0,
  //     totalCount: totalEventsCount[eventName]
  //   }));
  
  //   return eventArray.sort((a, b) => b.totalCount - a.totalCount).slice(0, 3);
  // };
  
  // const top3Events = getTop3Events(eventData.data, eventData.total_events_count);
  

  return (
    <React.Fragment>
      <Block className="h-300px">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h6" className="d-flex justify-content-between">
              <h4>Top 3 Events</h4>
              <span className="card-tools">
                <TooltipComponent
                  // icon="help-fill"
                  iconClass="card-hint"
                  direction="left"
                  id="Tooltip-3"
                  text="Calculated From present all records"
                />
              </span>
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        {isLoadingallData ? (
          <Row className="g-gs">
            {[...Array(3)].map((_, idx) => (
              <Col key={idx}>
                <ShimmerThumbnail height={100} width={150} className="m-0" rounded />
              </Col>
            ))}
          </Row>
        ) : (
          <>
           {eventCountsArrayOfObjects.length > 0 ? (
        <Row className="g-gs">
          {eventCountsArrayOfObjects.map((item) => (
            <Col key={item.eventName}>
              <ProjectCard>
                <div className="project-head mb-0 ">
                  <div className="project-info">
                    <h6 className="title">{item?.eventType}</h6>
                  </div>
                </div>
                <ul className="team-statistics py-1">
                  <li>
                    <span>{item.count}</span>
                    <span>Completed</span>
                  </li>
                  {/* <li>
                    <span>{item.totalCount}</span>
                    <span>Total</span>
                  </li> */}
                </ul>
              </ProjectCard>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="text-body text-center">
        {/* // <div className="text-body">No events found.</div> */}
        <CardImg

        src={Top_3_Events}
        alt="avatar"
        style={{ width: "23%", height: "20%", objectFit: "cover" }}
      />
      </div>
      )}
          </>
        )}
      </Block>
    </React.Fragment>
  );
};

export default TotalWages;
