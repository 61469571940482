import { useEffect, useState } from "react";
import defaultImage from "../../../assets/images/uploadImage.png";

const FileSelection2 = ({ filePath, inputId }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [initialImage, setInitialImage] = useState(null);

  // Set initial image based on filePath prop
  useEffect(() => {
    if (filePath) {
      setInitialImage(filePath);
    }
  }, [filePath]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileReaders = files.map((file) => new FileReader());
    const selectedImages = [];

    fileReaders.forEach((reader, index) => {
      reader.onload = (e) => {
        selectedImages.push(e.target.result);
        // When all files are read, update the state
        if (selectedImages.length === files.length) {
          setSelectedFiles(selectedImages);
        }
      };
      reader.readAsDataURL(files[index]);
    });
  };
  return (
    <>
      {selectedFiles.length > 0 ? (
        <div className="col-6 d-flex align-items-center justify-content-center flex-column my-3 w-100">
          <div className="img-fluid d-flex justify-content-center">
            {selectedFiles.map((file, index) => (
              <label key={`img-${index}`} htmlFor={inputId}>
                <img
                  src={file}
                  alt={`Selected file ${index + 1}`}
                  className="rounded-5 h-100px w-300px object-fit-contain"
                />
              </label>
            ))}
          </div>
        </div>
      ) : (
        <div className="col-6 d-flex align-items-center justify-content-center flex-column my-3 w-100">
          <div className="img-fluid d-flex justify-content-center">
            <label htmlFor={inputId}>
              <img
                src={initialImage || defaultImage}
                alt="Default"
                className="rounded-5 h-100px w-350px object-fit-contain"
              />
            </label>
          </div>
        </div>
      )}
      <div className="form-group border border-grey my-1 w-70">
        <div className="form-control-wrap">
          <div className="form-file d-flex align-items-center px-2">
            <input type="file" multiple id={inputId} onChange={handleFileChange} className="w-auto" accept="image/png, image/jpeg, image/jpg, image/gif," />
          </div>
        </div>
      </div>
    </>
  );
};

export default FileSelection2;
