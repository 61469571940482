import React, { useEffect, useState } from "react";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { formatDate, getCookie } from "../../utils/Utils";
import { PaginationComponent } from "../../components/Component";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const NewsUsers = ({allData}) => {
  // const [clientData, setClientData] = useState([]);
  // const [totalClientData, setTotalClientData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(5);
  const [newClient, setNewClient]= useState();
  useEffect(()=>{
    setNewClient(allData?.data?.clientData)
  },[allData])

  // useEffect(() => {
  //   clientInfo();
  //   return () => {};
  // }, []);
  // const clientInfo = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-client`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //     });

  //     if (!response?.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const responseData = await response.json();
  //     setClientData(responseData ? responseData?.data?.clients : []);
  //     setTotalClientData(responseData ? responseData?.data?.totalRecords : "No Client Data Available");
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = clientData ? clientData.slice(indexOfFirstItem, indexOfLastItem) : [];
  const currentItems = newClient ? newClient.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">Client Information</h4>{" "}
              <div className="fs-14px fw-normal mt-1 text-body">Total Clients : {newClient?.length} </div>
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top h-100" bodyclass="nk-tb-orders">
        {newClient?.length > 0 ? (
          <>
            <DataTableHead>
              <DataTableRow >
                <span className="text-dark tb-lead">Sr. No.</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark tb-lead">Client Username</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark tb-lead">Email</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark tb-lead">Contact No.</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark tb-lead">Registered Date</span>
              </DataTableRow>
            </DataTableHead>
            {currentItems?.map((item, idx) => {
              return (
                <DataTableItem key={idx}>
                  <DataTableRow >
                    <span className="tb-sub">
                      <a href="#order" className="text-dark">{indexOfFirstItem + idx + 1}</a>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub text-dark">
                      {item?.username}
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub text-dark">{item?.email}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub text-dark">{item?.work_phone_number ? item?.work_phone_number : "Not Provide"}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub text-dark">{formatDate(item?.updated_at)}</span>
                  </DataTableRow>
                </DataTableItem>
              );
            })}
          </>
        ) : (
          <div className="h-150px fs-2 justify-center align-items-md-center align-center my-3">
            <span>No Records Present</span>
          </div>
        )}
      </DataTableBody>
      {(newClient?.length < 10) ? null : (
        <div className="my-3 mx-auto">
          <PaginationComponent
            itemPerPage={itemPerPage}
            totalItems={newClient?.length || 0}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default NewsUsers;
