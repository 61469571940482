let sidebar1 = [
  { id: "dashboard", icon: "dashboard", label: "Overview", route: "" },
  { id: "add_event_id", icon: "calendar-alt", label: "Add event", route: "add-event" },
  { id: "event_list_id", icon: "icon ni ni-list-thumb-alt", label: "Event list", route: "event-list" },
  { id: "clients_id", icon: "user-c", label: "Clients", route: "client-list" },
  { id: "employee_id", icon: "icon ni ni-users", label: "Employee", route: "employee-list" },
  { id: "venue_id", icon: "icon ni ni-location", label: "Venue", route: "venue-list" },
  { id: "venue_id", icon: "icon ni ni-home-alt", label: "Venue", route: "vendor-list" },
  { id: "package_id", icon: "package", label: "Package", route: "package-list" },
  { id: "profile_id", icon: "user-circle", label: "Services", route: "Company-profile" },
  { id: "inquiry_id", icon: "user-list-fill", label: "Inquiry list", route: "inquiry-list" },
  { id: "plans_id", icon: "calendar-check", label: "Plans", route: "plan-selection" },
];

export default sidebar1;
