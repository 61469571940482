import React, { useContext, useEffect, useMemo, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  PreviewCard,
} from "../../components/Component";
import MonthlyGraph from "./Monthly_Sales/MonthlyGraph";
import TotalEvent from "./TotalEvent";
import TotalWages from "./TotalWages";
import DayToDayGraph from "./Daily_Sales/DayToDayGraph";
import NewsUsers from "./User";
import RecentActivity from "./Activity";
import UpcomingEvents from "./UpcomingEvents";
import EventComparison from "./Event Graph/EventComparison";
import { PlanContext } from "../context/PlanContext";
import { useNavigate } from "react-router";
import { getCompanyProfile, getDashboardData } from "../../http/get/getApi";
import Cookies from "js-cookie";
import { DataContext } from "../../context/DataProvider";
import NavigationHeader from "../components/NavigationHeader";
import { useQuery } from "react-query";

const HomePage = () => {
  const { setProfileProgress, profileprogress, userProfile, setUserProfile, setProfileCompletion, auOverview, auOverview1 } = useContext(DataContext);

  const [sm, updateSm] = useState(false);
  const { planState, setPlanState } = useContext(PlanContext);
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isError,
    refetch: refetchCompanyProfile,
  } = useQuery(
    ['companyProfile'], // Query key
    getCompanyProfile, // Query function
    {
      onSuccess: (data) => {
        const profileProgress = data?.data?.profile?.profile_progress
          ? JSON.parse(data.data.profile.profile_progress)
          : 0;
        setProfileProgress(profileProgress);
        setUserProfile(data?.data?.profile?.profile_image_path || '');
        setProfileCompletion(data?.data?.section_status);
      },
      enabled: !!planState, // This query will only run if planState is truthy
    }
  );

  const [dashboardData, setDashboardData] = useState(null);
  const memoizedAuOverview = useMemo(() => auOverview, [auOverview]);
  const memoizedAuOverview1 = useMemo(() => auOverview1, [auOverview1]);

  const {
    data: allData,
    isLoading: isLoadingallData,
    isError: isErrorAllError,
    refetch: refetchdashboardAllData,
  } = useQuery({
    queryKey: ["dashboardAllData"],
    queryFn: () => getDashboardData({ auOverview: memoizedAuOverview, auOverview1: memoizedAuOverview1 }),
    onSuccess: (data) => {
      setDashboardData(data);
    },
    enabled: false
    // staleTime:Infinity
  });

  useEffect(() => {
    if (planState === false) {
        navigate("/plan-selection");
    }else if(planState){
      refetchdashboardAllData();
      refetchCompanyProfile();
    }
  }, [planState, auOverview, auOverview1, refetchdashboardAllData]);
  

  // useEffect(()=>{
  //   getDashboardData();
  // },[dashboardData,auOverview,refetchdashboardAllData])


  // useEffect(() => {
  //   if (planState === false) {
  //         navigate("/plan-selection");
  //       }else {
  //     refetchCompanyProfile();
  //     refetchdashboardAllData();
  //   }
  // }, [planState, refetchCompanyProfile,refetchdashboardAllData]);


  // useEffect(() => {
  //   if (planState === false) {
  //     navigate("/plan-selection");
  //   }

  //   const updateProfile = async () => {
  //     const data = await getCompanyProfile();
  //     // const userID = data?.data?.profile?.user_id ? JSON.parse(data?.data?.profile?.user_id) : 0;
  //     // Cookies.set("userID", userID);
  //     const profileProgress = data?.data?.profile?.profile_progress
  //       ? JSON.parse(data?.data?.profile?.profile_progress)
  //       : 0;
  //     setProfileProgress(profileProgress);
  //     setUserProfile(data?.data?.profile?.profile_image_path || "")
  //     setProfileCompletion(data?.data?.section_status)
    
  //   };
  //   updateProfile();

  // }, [planState]);

  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      {/* <Content> */}
      <NavigationHeader
        heading={"Overview"}
        
      />
        <Block className="m-4">
          {/* <Row className="g-gs"> */}
          <Row className="g-2">
            <Col xxl="6" lg={6} md={6}>
              {/* <Row className="g-gs justify-around"> */}
              <Row className="g-2 justify-around">
                <Col md={12} lg={12}>
                  <PreviewAltCard className="card-bordered h-100">
                    <TotalEvent allData={dashboardData} />
                  </PreviewAltCard>
                </Col>
                <Col md={12} lg={12}>
                  <PreviewAltCard className="card-bordered h-100">
                    <TotalWages allData={dashboardData}  isLoadingallData={isLoadingallData}/>
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
            <Col xxl="6" lg={6} md={6}>
              {/* <Row className="g-gs"> */}
              <Row className="g-2">
                <Col lg={12} md={12} xxl="12">
                  <PreviewAltCard className="card-bordered h-100">
                    <MonthlyGraph allData={dashboardData} />
                  </PreviewAltCard>
                </Col>
                <Col lg={12} md={12} xxl="12">
                  <PreviewAltCard className="card-bordered h-100">
                    <DayToDayGraph allData={dashboardData} />
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="g-gs my-2 "> */}
          <Row className="g-2 my-2 ">
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <EventComparison allData={dashboardData} />
              </Card>
            </Col>
          </Row>
          {/* <Row className="g-gs"> */}
          <Row className="g-2 mb-2">
            <Col xxl="8" lg={8} md={8} className="">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <NewsUsers allData={dashboardData}/>
              </Card>
            </Col>
            <Col xxl="4" lg={4} md={4}>
              <Card className="card-bordered card-full overflow-auto">
                <RecentActivity  allData={dashboardData}/>
              </Card>
            </Col>
            <Col xxl="12" xl="12" lg="12" md="12">
              <Card className="card-bordered card-full overflow-auto">
                <UpcomingEvents allData={dashboardData} />
              </Card>
            </Col>
          </Row>
        </Block>
      {/* </Content> */}
    </React.Fragment>
  );
};

export default HomePage;
