const getCroppedImg = (imageSrc, cropAreaPixels) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = cropAreaPixels.width;
      canvas.height = cropAreaPixels.height;

      ctx.drawImage(
        image,
        cropAreaPixels.x,
        cropAreaPixels.y,
        cropAreaPixels.width,
        cropAreaPixels.height,
        0,
        0,
        cropAreaPixels.width,
        cropAreaPixels.height
      );

      // Use toDataURL to get the image in base64 format
      const base64Image = canvas.toDataURL("image/jpeg");
      resolve(base64Image);
    };

    image.onerror = (error) => {
      reject(new Error("Failed to load image: " + error));
    };
  });
};

export default getCroppedImg;
