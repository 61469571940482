
import Router from "./route/Index";
import "./App.css";
import ThemeProvider from "./layout/provider/Theme";
import ToastLayout from "./components/toast/ToastLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import DataProvider from "./context/DataProvider";

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <DataProvider>
          <Router />
          <ToastLayout />
        </DataProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
