import React, { useContext, useEffect, useState } from "react";
import { CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon, TooltipComponent } from "../../../components/Component";
import { EventPriceBar } from "./EventPriceBar";
import { getCookie } from "../../../utils/Utils";
import { DataContext } from "../../../context/DataProvider";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const MonthlyGraph = ({allData}) => {
  const { auOverview, setAuOverview} = useContext(DataContext);
  // const [auOverview, setAuOverview] = useState("1 month");

  const [eventData, setEventData] = useState({});  
  const [auOverview1, setauOverview] = useState("");
  const [newMonthlyData, setNewMonthlyData]= useState();


  useEffect(()=>{
    setNewMonthlyData(allData?.data?.eventChartData);
    setauOverview(auOverview);
  },[allData, auOverview])
  

  // useEffect(() => {
  //   eventInfo();
  //   // setauOverview(auOverview)
  // }, [auOverview]);

  // const eventInfo = async () => {
  //   // setLoading(true);
  //   const formdata = new FormData();
  //   formdata.append("time_period", auOverview);
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-events-by-chart`, {
  //       method: "POST",
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //       body: formdata,
  //     });

      
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
      
  //     const responseData = await response.json();
  //     // console.log("responseData",responseData)
  //     setEventData(responseData.data || []);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h4 className="title">
            {/* {auOverview === "1 month" ? "Monthly ":auOverview === "3 months" ? "3 Months " : "Yearly "} */}
            Event Analysis{" "}
          </h4>
          <p className="fs-6">Annual event metrics reveal revenue growth</p>
        </CardTitle>
        <div className="card-tools">
          <div className="d-block d-lg-none w-auto">
            <ul className="d-flex g-3">
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt">
                      <li>
                        <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                          <a
                            href="#navitem"
                            className={auOverview === "1 month" ? "nav-link text-primary bg-lighter" : "nav-link"}
                            onClick={(e) => {
                              e.preventDefault();
                              setAuOverview("1 month");
                            }}
                          >
                            1 M
                          </a>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                          <a
                            href="#navitem"
                            className={auOverview === "3 months" ? "nav-link text-primary bg-lighter" : "nav-link"}
                            onClick={(e) => {
                              e.preventDefault();
                              setAuOverview("3 months");
                            }}
                          >
                            3 M
                          </a>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                          <a
                            href="#navitem"
                            className={auOverview === "1 year" ? "nav-link text-primary bg-lighter" : "nav-link"}
                            onClick={(e) => {
                              e.preventDefault();
                              setAuOverview("1 year");
                            }}
                          >
                            1 Y
                          </a>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </div>
          <div className="card-tools shrink-0 w-150px d-none d-lg-block ">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "1 month" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("1 month");
                  }}
                >
                  1 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "3 months" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("3 months");
                  }}
                >
                  3 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "1 year" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("1 year");
                  }}
                >
                  1 Y
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sale-data-group flex-md-nowrap g-4">
          <div className="nk-sale-data">
            <span className="amount">
              0.00{" "}
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                0.00%
              </span>
            </span>
            <span className="sub-title fs-6">This {auOverview} Event Revenue</span>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sales-ck sales-revenue">
          {/* <EventPriceBar auOverview={auOverview} eventData={eventData} /> */}
          <EventPriceBar auOverview={auOverview} eventData={newMonthlyData} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default MonthlyGraph;
